import React from "react";

import { landing, pageSvg, bubbleSvg } from "./Welcome.module.scss";

const Welcome = () => {
  return (
    <main className={landing}>
      <svg
        className={bubbleSvg}
        width="239"
        height="259"
        viewBox="0 0 239 259"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="contour"
          d="M199.089 258.02C190.73 257.998 182.597 255.309 175.873 250.346C169.148 245.382 164.182 238.402 161.698 230.422C135.174 240.565 105.899 240.901 79.1486 231.369C52.3986 221.837 29.9331 203.063 15.801 178.431C1.66887 153.8 -3.20043 124.931 2.07065 97.0264C7.34173 69.1222 22.4065 44.0184 44.55 26.2393C66.6934 8.46022 94.4591 -0.824815 122.843 0.0575713C151.227 0.939957 178.362 11.9317 199.359 31.0519C220.355 50.1721 233.831 76.1632 237.359 104.341C240.887 132.519 234.234 161.03 218.599 184.736C226.101 189.037 231.973 195.696 235.301 203.678C238.629 211.66 239.227 220.518 237.002 228.874C234.777 237.231 229.854 244.619 222.998 249.89C216.142 255.16 207.736 258.019 199.089 258.02V258.02ZM163.014 227.761L163.312 228.827C165.977 238.329 172.307 246.384 180.909 251.219C189.512 256.055 199.683 257.276 209.186 254.614C218.688 251.952 226.744 245.624 231.582 237.022C236.42 228.421 237.644 218.25 234.984 208.747C233.62 203.917 231.297 199.411 228.155 195.497C225.013 191.583 221.115 188.342 216.693 185.967L215.717 185.445L216.335 184.527C231.971 161.284 238.76 133.207 235.471 105.387C232.182 77.5676 219.032 51.8479 198.407 32.8921C177.781 13.9362 151.045 2.99954 123.048 2.0652C95.05 1.13086 67.6445 10.2608 45.801 27.7993C23.9574 45.3379 9.12223 70.1237 3.98571 97.6619C-1.1508 125.2 3.75147 153.668 17.8035 177.901C31.8556 202.135 54.1269 220.531 80.5788 229.753C107.031 238.974 135.911 238.412 161.984 228.166L163.014 227.761Z"
          fill="#3F3D56"
        />
        <path
          id="firstLine"
          d="M183.481 104.095H54.0403C53.2461 104.095 52.4598 103.938 51.7261 103.634C50.9924 103.33 50.3257 102.885 49.7642 102.323C49.2026 101.762 48.7572 101.095 48.4532 100.362C48.1493 99.6279 47.9929 98.8415 47.9929 98.0474C47.9929 97.2532 48.1493 96.4668 48.4532 95.7331C48.7572 94.9994 49.2026 94.3328 49.7642 93.7712C50.3257 93.2097 50.9924 92.7642 51.7261 92.4603C52.4598 92.1564 53.2461 92 54.0403 92H183.481C184.275 92 185.061 92.1564 185.795 92.4603C186.528 92.7642 187.195 93.2097 187.757 93.7712C188.318 94.3328 188.764 94.9994 189.068 95.7331C189.372 96.4668 189.528 97.2532 189.528 98.0474C189.528 98.8415 189.372 99.6279 189.068 100.362C188.764 101.095 188.318 101.762 187.757 102.323C187.195 102.885 186.528 103.33 185.795 103.634C185.061 103.938 184.275 104.095 183.481 104.095H183.481Z"
          fill="#6C63FF"
        />
        <path
          id="secondLine"
          d="M183.481 125.095H54.0403C53.2461 125.095 52.4598 124.938 51.7261 124.634C50.9924 124.33 50.3257 123.885 49.7642 123.323C49.2026 122.762 48.7572 122.095 48.4532 121.362C48.1493 120.628 47.9929 119.842 47.9929 119.047C47.9929 118.253 48.1493 117.467 48.4532 116.733C48.7572 115.999 49.2026 115.333 49.7642 114.771C50.3257 114.21 50.9924 113.764 51.7261 113.46C52.4598 113.156 53.2461 113 54.0403 113H183.481C184.275 113 185.061 113.156 185.795 113.46C186.528 113.764 187.195 114.21 187.757 114.771C188.318 115.333 188.764 115.999 189.068 116.733C189.372 117.467 189.528 118.253 189.528 119.047C189.528 119.842 189.372 120.628 189.068 121.362C188.764 122.095 188.318 122.762 187.757 123.323C187.195 123.885 186.528 124.33 185.795 124.634C185.061 124.938 184.275 125.095 183.481 125.095H183.481Z"
          fill="#6C63FF"
        />
        <path
          id="thirdLine"
          d="M183.473 146.095H54.0327C52.4302 146.093 50.8939 145.455 49.7615 144.321C48.629 143.187 47.9929 141.65 47.9929 140.048C47.9929 138.445 48.629 136.908 49.7615 135.774C50.8939 134.64 52.4302 134.002 54.0327 134H183.473C185.077 134 186.615 134.637 187.749 135.771C188.884 136.905 189.521 138.444 189.521 140.048C189.521 141.652 188.884 143.19 187.749 144.324C186.615 145.458 185.077 146.095 183.473 146.095H183.473Z"
          fill="#6C63FF"
        />
      </svg>

      <h1>Welcome</h1>
      <h2>
        My name is Gabriel and I love building web sites and apps with{" "}
        <mark>React</mark> and <mark>NodeJS</mark>
      </h2>
      <h3>
        If you're looking for a passionate guy who enjoys solving problems,
        <i> you're</i> in the <i>right</i> place.
      </h3>
      <svg
        className={pageSvg}
        width="314"
        height="175"
        viewBox="0 0 314 175"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M313.592 21.6154H0.729446V6.33381C0.730976 4.98331 1.27087 3.6885 2.23064 2.73354C3.19041 1.77859 4.49169 1.24148 5.84901 1.23996H308.472C309.83 1.24148 311.131 1.77859 312.091 2.73354C313.05 3.6885 313.59 4.98331 313.592 6.33381V21.6154Z"
          fill="#6C63FF"
        />
        <path
          id="Vector_2"
          d="M308.312 175H5.68841C4.18028 174.998 2.73439 174.401 1.66798 173.34C0.601574 172.279 0.00172535 170.841 0 169.34V5.65984C0.00172535 4.15928 0.601574 2.72066 1.66798 1.65961C2.73439 0.598553 4.18028 0.00171669 5.68841 0H308.312C309.82 0.00171669 311.266 0.598553 312.332 1.65961C313.398 2.72066 313.998 4.15928 314 5.65984V169.34C313.998 170.841 313.398 172.279 312.332 173.34C311.266 174.401 309.82 174.998 308.312 175V175ZM5.68841 2.26393C4.78351 2.26492 3.91597 2.62301 3.27611 3.25966C2.63625 3.8963 2.27635 4.75949 2.27536 5.65984V169.34C2.27635 170.241 2.63625 171.104 3.27611 171.74C3.91597 172.377 4.78351 172.735 5.68841 172.736H308.312C309.216 172.735 310.084 172.377 310.724 171.74C311.364 171.104 311.724 170.241 311.725 169.34V5.65984C311.724 4.75949 311.364 3.8963 310.724 3.25966C310.084 2.62301 309.216 2.26492 308.312 2.26393H5.68841Z"
          fill="#3F3D56"
        />
        <g className="dots">
          <path
            id="dotOne"
            d="M13.8709 18.5161C17.7617 18.5161 20.9158 15.3779 20.9158 11.5066C20.9158 7.6354 17.7617 4.49713 13.8709 4.49713C9.98016 4.49713 6.82605 7.6354 6.82605 11.5066C6.82605 15.3779 9.98016 18.5161 13.8709 18.5161Z"
            fill="#FF4040"
          />
          <path
            id="dotTwo"
            d="M32.9927 18.5161C36.8835 18.5161 40.0376 15.3779 40.0376 11.5066C40.0376 7.6354 36.8835 4.49713 32.9927 4.49713C29.1019 4.49713 25.9479 7.6354 25.9479 11.5066C25.9479 15.3779 29.1019 18.5161 32.9927 18.5161Z"
            fill="#FFF61A"
          />
          <path
            id="dotThree"
            d="M52.1145 18.5161C56.0053 18.5161 59.1594 15.3779 59.1594 11.5066C59.1594 7.6354 56.0053 4.49713 52.1145 4.49713C48.2237 4.49713 45.0697 7.6354 45.0697 11.5066C45.0697 15.3779 48.2237 18.5161 52.1145 18.5161Z"
            fill="white"
          />
        </g>
        <path
          id="lineOne"
          d="M221.847 73.7655H74.5854C72.7607 73.7655 71.0108 73.0443 69.7205 71.7605C68.4303 70.4768 67.7054 68.7356 67.7054 66.9201C67.7054 65.1046 68.4303 63.3634 69.7205 62.0796C71.0108 60.7958 72.7607 60.0747 74.5854 60.0747H221.847C223.672 60.0747 225.422 60.7958 226.712 62.0796C228.002 63.3634 228.727 65.1046 228.727 66.9201C228.727 68.7356 228.002 70.4768 226.712 71.7605C225.422 73.0443 223.672 73.7655 221.847 73.7655V73.7655Z"
          fill="#FFDB5A"
        />
        <path
          id="lineTwo"
          d="M221.847 94.6975H74.5854C72.7607 94.6975 71.0108 93.9762 69.7205 92.6925C68.4303 91.4087 67.7054 89.6676 67.7054 87.852C67.7054 86.0365 68.4303 84.2954 69.7205 83.0116C71.0108 81.7278 72.7607 81.0066 74.5854 81.0066H221.847C223.672 81.0066 225.422 81.7278 226.712 83.0116C228.002 84.2954 228.727 86.0365 228.727 87.852C228.727 89.6676 228.002 91.4087 226.712 92.6925C225.422 93.9762 223.672 94.6975 221.847 94.6975V94.6975Z"
          fill="#FFDB5A"
        />
        <path
          id="lineThree"
          d="M221.847 115.629H74.5854C72.7622 115.627 71.0144 114.905 69.726 113.621C68.4376 112.338 67.714 110.598 67.714 108.784C67.714 106.97 68.4376 105.23 69.726 103.946C71.0144 102.663 72.7622 101.94 74.5854 101.938H221.847C222.751 101.937 223.647 102.113 224.483 102.457C225.319 102.8 226.078 103.304 226.718 103.94C227.358 104.576 227.865 105.331 228.212 106.162C228.558 106.993 228.736 107.884 228.736 108.784C228.736 109.684 228.558 110.574 228.212 111.406C227.865 112.237 227.358 112.992 226.718 113.628C226.078 114.263 225.319 114.767 224.483 115.111C223.647 115.454 222.751 115.631 221.847 115.629V115.629Z"
          fill="#FFDB5A"
        />
      </svg>
    </main>
  );
};

export default Welcome;
